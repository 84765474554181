<template>
  <div class="container">
    <!-- 按条件筛选查找数据 -->
    <el-form ref="form" :inline="true" :model="formInline" small="size">
      <el-form-item label="组长医生姓名:">
        <el-input
          v-model.trim="formInline.doctorName"
          v-check-permission
          placeholder="请输入组长医生姓名"
        />
      </el-form-item>
      <el-form-item label="患者姓名:">
        <el-input
          v-model.trim="formInline.patientName"
          v-check-permission
          placeholder="请输入患者姓名"
        />
      </el-form-item>
      <el-form-item label="手机号码:">
        <el-input
          v-model="formInline.patientMobile"
          v-check-permission
          placeholder="请输入手机号码"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="queryData()"> 查询 </el-button>
        <el-button type="primary" @click="queryClear()"> 重置 </el-button>
      </el-form-item>
    </el-form>
    <el-table
      ref="multipleTable"
      v-loading="loading"
      :data="orderListData"
      border
      class="width-96"
    >
      <el-table-column type="index" label="序号" width="50" />
      <el-table-column prop="groupId" label="专病小组群聊ID" align="center" />
      <el-table-column prop="doctorName" label="组长医生姓名" align="center" />
      <el-table-column prop="patientName" label="患者姓名" align="center" />
      <el-table-column prop="patientMobile" label="手机号码" align="center" />
      <el-table-column prop="createdDate" label="创建时间" align="center" />
      <el-table-column prop="chatStatus" label="群聊状态" align="center">
        <template slot-scope="scope">
          {{ getChatStatus(scope.row.chatStatus) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="tipMessageStatus"
        label="模板消息提醒"
        align="center"
      >
        <template slot-scope="scope">
          <el-switch
            :value="scope.row.tipMessageStatus"
            :active-value="1"
            :inactive-value="0"
            @change="
              (val) => {
                updateTipMessageStatus(scope.row, val);
              }
            "
          />
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        show-overflow-tooltip
        width="200"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="mini"
            @click="dataOperation(scope.row.groupId)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页功能模块 -->
    <div class="padding-30">
      <el-pagination
        background
        :page-sizes="[10, 20, 50, 100, 200]"
        :current-page="pageInfo.page"
        :page-size="pageInfo.size"
        layout="->,total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdvisoryGroup',
  data() {
    return {
      loading: false,
      formInline: {
        doctorName: '',
        patientName: '',
        patientMobile: '',
      },
      pageInfo: {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      },
      orderListData: [], // 列表
      chatMap: {
        // 群聊状态集合
        0: '进行中',
        1: '已结束',
      },
      tipMessageMap: {
        // 模板消息提醒集合
        0: '关闭',
        1: '开启',
      },
    };
  },
  mounted() {
    this.queryPageByCondition();
  },
  methods: {
    getChatStatus(chatStatus) {
      return this.chatMap[chatStatus];
    },
    getTipMessageStatus(tipMessageStatus) {
      return this.tipMessageMap[tipMessageStatus];
    },
    // 分页方法调用
    handleSizeChange(currentPage) {
      this.pageInfo.size = currentPage;
      this.queryPageByCondition();
    },
    handleCurrentChange(currentPage) {
      this.pageInfo.page = currentPage;
      this.queryPageByCondition();
    },
    // 重置
    queryClear() {
      this.formInline = {
        // 查询条件
        doctorName: '',
        occupationCode: '',
        professionalTitleCode: '',
      };
      this.pageInfo = {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      };
      this.queryPageByCondition();
    },
    queryData() {
      this.pageInfo.page = 1;
      this.queryPageByCondition();
    },
    // 分页查询问诊类型列表
    queryPageByCondition() {
      this.loading = true;
      const { doctorName, patientName, patientMobile } = this.formInline;
      const params = {
        doctorName: doctorName || undefined,
        patientName: patientName || undefined,
        patientMobile: patientMobile || undefined,
        page: {
          done: true,
          pageIndex: this.pageInfo.page,
          pageSize: this.pageInfo.size,
        },
      };
      this.$api
        .queryPageByCondition(params)
        .then((res) => {
          this.orderListData = res.data.data;
          this.pageInfo.total = Number(res.data.total); // 获取总共有多少条数据
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 模板消息提醒开关
    updateTipMessageStatus(item, tipMessageStatus) {
      const { groupId, patientName } = item;
      const tipMessageMap = {
        0: '关闭',
        1: '开启',
      };
      this.$confirm(
        `${tipMessageMap[tipMessageStatus]}${patientName}的模板消息提醒，是否继续?`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then(() => {
          const param = {
            groupId,
            tipMessageStatus,
          };
          this.$api.setImGroupConfigure(param).then(async (res) => {
            if (res.code === 0) {
              await this.queryPageByCondition();
              this.$message({
                type: 'success',
                message: `${tipMessageMap[tipMessageStatus]}成功!`,
              });
            }
          });
        })
        .catch(() => {});
    },
    // 咨询详情
    dataOperation(groupId) {
      this.$router.push({
        path: '/doctorManage/advisoryGroupDetail',
        query: {
          groupId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin-top: 20px;
}

.width-96 {
  width: 96%;
}

.padding-30 {
  padding-right: 56px;
  margin-top: 10px;
}

.tipMessageStatus {
  color: #1890ff;
  cursor: pointer;
}
</style>
